import React from 'react';
import CryptoSelector from './CryptoSelector';
import cryptoData from "./data";
import Heading from './Heading';

const App = () => {
  const crypto = cryptoData

  return (
    <div className='flex-container'>
      <div> <Heading/> </div>
      <div className='fadein'> <CryptoSelector cryptoData={crypto} /> </div>
    </div>
  );
};

export default App;
