import React from "react";
/* import BuyButton from "./BuyButton"; */

function Heading() {
  return (
    <div className="flex-container-a">
      <h2>EZMARKETCAP</h2>
    {/*   <BuyButton/> */}
    </div>
  );
}

export default Heading;
